import { ComponentProps, FC } from 'react'

type TSvgProps = ComponentProps<'svg'>

interface IFBookMarkProps extends TSvgProps {
  isOn?: boolean
  strokeType?: '#666666' | '#ffffff' | '#01a0ff' | '#333'
}

const BookMark: FC<IFBookMarkProps> = ({
  isOn = true,
  strokeType = '#ffffff',
  ...props
}) => {
  return isOn ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      aria-label="스크랩"
      {...props}
    >
      <desc>스크랩</desc>
      <path
        fill="currentColor"
        d="M9.006 0H2.994A2.994 2.994 0 000 2.994V16l6-2.368L12 16V2.994A2.994 2.994 0 009.006 0z"
      ></path>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 8.7 11.2"
      {...props}
    >
      <desc>스크랩</desc>
      <path
        data-name="패스 476"
        d="M613.254 99.171H609.5a1.871 1.871 0 0 0-1.871 1.871v8.129l3.75-1.48 3.75 1.48v-8.129a1.871 1.871 0 0 0-1.875-1.871z"
        transform="translate(-607.025 -98.571)"
        style={{
          fill: 'none',
          stroke: strokeType,
          strokeLinejoin: 'round',
          strokeWidth: '1.2px',
        }}
      />
    </svg>
  )
}

export default BookMark
