import { ComponentProps, FC } from 'react'

type TSvgProps = ComponentProps<'svg'>

interface IFPlusProps extends TSvgProps {
  color?: string
  stroke?: string
}

const PlusIcon: FC<IFPlusProps> = ({
  color = 'curentColor',
  stroke = '',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      aria-label="플러스"
      {...props}
    >
      <desc>플러스</desc>
      <path
        fill={color}
        stroke={stroke}
        d="M9.428 20.428v-7.857H1.571a1.571 1.571 0 010-3.143h7.857V1.571a1.571 1.571 0 013.143 0v7.857h7.857a1.571 1.571 0 110 3.143h-7.857v7.857a1.571 1.571 0 11-3.143 0z"
      ></path>
    </svg>
  )
}

export default PlusIcon
